body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.accordion{
  margin-top: 30px;
}
.acco{
  text-align: start;
  width: 100%;
  border: none;
  border: none;
  border-bottom: 1px solid orange;
  border-radius: 0px 10px 0px 10px;
  font-size: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: orange;
}

.bi-chevron-compact-down{
  color: orange;
}

.car{
  height: 475px;
  background-image: url(/public/carousl2.png);
  border-radius: 0px 15px 0px 15px;
}

#h1{
  font-size: 50px;
  font-family: 'Times New Roman', Times, serif;
  color: rgb(252, 117, 6);
  text-align: start;
  
}

.bd{
  padding-top: 90px;
  color: white;
}

.p1{
  font-size: 20px;
  width: 400px;
}

.p1 span{
  color: orange;
}

.toabout{
  background-color:  rgba(255, 255, 255, 0);
  border: 2px solid rgb(252, 117, 6);
  color: rgb(252, 117, 6);
  padding: 5px 5px 5px 5px ;
  margin-bottom: 20px;

}

.toabout:hover{
  background-color: rgb(252, 117, 6);
  border: 2px solid rgb(252, 117, 6);
  color: white;
  padding: 5px 5px 5px 5px ;
  margin-bottom: 20px;

}

.nav-link:hover{
      
      display: block;
      border-bottom: 2px solid rgb(252, 117, 6);
      
      
}

.nav-link{
    margin-left: 10px;
}
.navbar-nav{
      margin-left: 36%;
}

#principe{
  text-align: start;
  
}

.rowp{
  height: 100%;
}

@keyframes slideInleft{
  from {
      transform: translateX(-300px);
  }
  to{
    transform: translateX(0);
  }
}

#li1{
  font-size: 20px;
  padding: 20px;
  animation-name: slideInleft ;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
}

#li2{
  font-size: 20px;
  padding: 20px;
  animation-name: slideInleft ;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
}

#li3{
  font-size: 20px;
  padding: 20px;
  animation-name: slideInleft ;
  animation-duration: 2s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
}

#principe{
  color: rgb(252, 117, 6);
  font-size: 35px;
}

#li1,#li2,#li3{
  list-style: none;
}

.bi-dropbox{
  color:rgb(252, 117, 6) ;
  margin-right: 15px;
  
}

.card-body{
  height: 310px;
  
}

.row33{
    margin-bottom: 30px;
}

.h1srv{
  font-size: 35px;
  margin: 10px;
  color: rgb(252, 117, 6);
}



.bgprincipe .rounded{
  height: 400px;
  width: 100%;
}
.tosrv{
  background-color:  rgb(252, 117, 6);
  border: 2px solid rgb(252, 117, 6);
  color: white;
  border-radius: 6px;
  padding: 5px 5px 5px 5px ;
  margin-bottom: 20px;

}

.cards:hover{
    background-color: rgb(252, 117, 6) ;
    box-shadow: 1px 3px 5px 0.5px rgb(252, 117, 6) ;
    border: none;
}

.cards:hover .tosrv{
  background-color:  white;
  border: 2px solid rgb(252, 117, 6);
  color: rgb(252, 117, 6);
  padding: 5px 5px 5px 5px ;
  
}
.cards{
 
  text-align: start;
  max-height: 320px;
  display: inline-block;
} 
.rowC{
  margin-top: 50px;
}

.imgC{
  height: 30px;
  width: 90px;
  margin: 10px;
}

.all{
  background-color: rgb(251, 250, 250);
  height: 100%;
}

.tit{
  font-size: 30px;
  color: rgb(252, 117, 6);
}

.all2{
  background-color: rgb(241, 241, 241);
  
}

.imgF{
  padding-top: 30px;
  
}

.imgF .chat{
  margin-top: 20px;
  
  
}

.imgF .chat .links{
  margin: 10px;
  color: rgb(252, 117, 6);
  
  
}

.imgF .chat .links:hover{
  margin: 10px;
  color: white;

  
  
}

.limk ul .lil {
  list-style: none;
  
  
  
  

}

.limk ul .lil .nav-linkk{
  text-decoration: none;
  color: rgb(252, 117, 6) ;
  
  

}

.limk ul .lil .nav-linkk:hover{
  text-decoration: none;
  
  color: white ;

  
  border-bottom: 2px solid rgb(252, 117, 6);

}

.limk ul .lil .loca{
  
  margin-right: 8px;
  color: rgb(252, 117, 6)  ;

}

.limk ul .lil .links{
  color: rgb(252, 154, 6) ;
  text-decoration: none;

}

#lien{
  color: rgb(252, 117, 6);
  font-family: 'Times New Roman', Times, serif;
}





.figureamib .span2{
  color: rgb(252, 154, 6);
}


.figure{
  position: relative;
}

.figure .img-fluid{
  height: 470px;
  width: 1200px;
}

.fig{
  margin-top: 30px;
  height: 490px;
}

.figureami{
  font-size: 40px;
  color: rgb(252, 154, 6);
  position: relative;
  bottom: 280px;
  margin-bottom: 25px;
  font-family: 'Times New Roman', Times, serif;
  
}

.figureamib {
  font-size: 16px;
  color: white;
  position: relative;
  bottom: 300px;
  width: 90%;

}

.figureamib .width{
  width: 80%;
}
.typo{
  color: rgb(252, 154, 6);
}

.REC{
  position: relative;
  top: 30px;
}

.hrec{
  color: rgb(252, 154, 6);
}



.lii{
  list-style:none;
}

.lii li{
  padding: 6px;
}

.lii li svg{
  margin-right: 9px;
  color: rgb(252, 154, 6) ;
}

.backy .imglogo{
  height: 310px;
  box-shadow: 2px 2px 5px 4px rgb(252, 154, 6) ;
}


.banner-card{
  position: relative;
}

.banner-card img{
  height: 500px;
  width: 100%;
  border-radius: 10px;
  
}

.banner-text{
  position: absolute;
  top: 42%;
}

.h1ban{
  font-size: 40px;
  color: rgb(252, 154, 6);
  font-family: 'Times New Roman', Times, serif;
}

.pban{
  color: white;
}

.pjudi{
  margin: 10px;
  padding: 10px;
}

.recc{
  color: rgb(252, 154, 6) ;
}

.rowenq{
  position: relative;
  top: 15;
}

.recco{
  margin-top: 15px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgb(252, 154, 6) ;
}

.texty{
  margin-right: 10px;
  color: rgb(252, 154, 6);
}

.backy .imglogoo{
  height: 100%;
  box-shadow: 2px 2px 5px 4px gainsboro ;
}

.backy .imglogogray{
  height: 100%;
  box-shadow: 2px 2px 5px 4px gainsboro ;
}



.row1{
  height: 100%;
  background-color: rgb(252, 154, 6);
  width: 100%;
}

.ally{
  position: relative;
  width: 100%;
  height: 100%;
  
}

.NOTR1{
  font-size: 25px;
  position: absolute;
  top: 50px;
  left: 100px;
  color: white;
  
}

.NOTR2{
  color: white;
  padding-top: 50px;
  position: relative;
  left: 10px;
  font-size: 20px; 
}

.NOTR3{
  font-size: 25px;
  position: absolute;
  top: 50px;
  left: 100px;
  color: rgb(252, 154, 6);
  
}

.row3{
  height: 100%;
  background-color: white;
  width: 100%;
}


.NOTR4{
  color: rgb(252, 154, 6);
  padding-top: 50px;
  position: relative;
  left: 10px;
  font-size: 20px;
  
}

.NOTR5{
  font-size: 25px;
  position: absolute;
  padding-bottom: 20px;
  top: 50px;
  left: 100px;
  color: white;
}

.NOTR6{
  color: white;
  padding-top: 50px;
  position: relative;
  left: 10px;
  font-size: 20px;
 
  
}

.row2{
  height: 100%;
  background-color: rgb(252, 154, 6);
  width: 100%;
}



#faq{
  margin-top: 100px;
  padding-top: 0;
}

.propos{
  font-size: 40px;
  margin-top: 25px;
}

.color{
  background-color: rgb(252, 125, 6) ;
  color: white;
}

.btttn{
  background-color: black;
  width: 250px;
  height: 80px;
  color: rgb(252, 125, 6);
}

.row4{
  height: 100%;
  background-color: white;
  width: 100%;
}


.NOTR8{
  color: rgb(252, 154, 6);
  padding-top: 50px;
  position: relative;
  left: 10px;
  font-size: 20px;
  
}

.NOTR7{
  font-size: 25px;
  position: absolute;
  padding-bottom: 20px;
  top: 50px;
  left: 100px;
  color: rgb(252, 154, 6);
}


@media screen and (max-width:430px) {
  .NOTR2{
    color: white;
    padding-top: 50px;
    position: relative;
    left: 10px;
    font-size: 20px;
    margin-top: 20px;
    
  }
  .NOTR4{
    color: rgb(252, 125, 6);
    padding-top: 50px;
    position: relative;
    left: 10px;
    font-size: 20px;
    margin-top: 20px;
    
  }
  .NOTR6{
    color: white;
    padding-top: 50px;
    position: relative;
    left: 10px;
    font-size: 20px;
    margin-top: 20px;
    
  }
  .NOTR3{
      font-size: 25px;
      position: absolute;
      top: 30px;
      left: 70px;
      color: rgb(252, 154, 6);
  }

  .NOTR5{
    font-size: 25px;
    position: absolute;
    top: 30px;
    left: 90px;
    color: white;
  }

  .NOTR1{
    font-size: 25px;
    position: absolute;
    top: 30px;
    left: 50px;
    color: white;
  }

  #h1{
    font-size: 38px;
    font-family: 'Times New Roman', Times, serif;
    color: rgb(252, 117, 6);
    text-align: start;
    
  }

  .p1{
    font-size: 15px;
    width: 280px;
  }

  .h1ban{
    position: relative;
    bottom: 70px;
    font-size: 30px;
  }

  .pban{
    position: relative;
    bottom: 70px;
  }

  .figureami{
    /* position: absolute; */
    font-size: 30px;
    margin-bottom: 20px;
  }

  .figureamib{
    font-size: 17px;
    margin-top: 40px;
    position:absolute;
    max-width: 100%;
    top: 220px;
  }

 

  #ser{
    margin-bottom: 10rem;
  }
  .NOTR8{
    color: rgb(252, 154, 6);
    padding-top: 50px;
    position: relative;
    left: 10px;
    font-size: 20px;
    top: 20px;
    
  }
  
  .NOTR7{
    font-size: 25px;
    position: absolute;
    padding-bottom: 20px;
    top: 20px;
    left: 30px;
    color: rgb(252, 154, 6);
  }
}
