body {
	align-items: center;
	justify-content: center;
}

.slider {
	height: 100px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: auto; /* Adjust width as needed */
}
  
.slide-track {
	animation: scroll 80s linear infinite; /* Slower speed, adjust as needed */
	display: flex;
	width: fit-content;
}
  
.slide {
	background-repeat: no-repeat;
	background-size: cover;
	width: 250px;
	height: 100px;
}
  
/* Define keyframe animation for scrolling */
@keyframes scroll {
	0% {
	  transform: translateX(0);
	}
	100% {
	  transform: translateX(calc(-250px * 28)); /* Adjust based on total number of slides */
	}
}
  
/* Pause animation on hover */
.slider:hover .slide-track {
	animation-play-state: paused;
}
  
.clt {
	margin-top: 70px;
	margin-bottom: 50px;
}
